import { HttpParameterCodec } from '@angular/common/http';

/**
 * Unlike the default encoder encodes plus signs as %2B
 */
export class ApiParameterCodec implements HttpParameterCodec {
    encodeKey(k: string): string {
        return encodeURIComponent(k);
    }

    encodeValue(v: string): string {
        return encodeURIComponent(v);
    }

    decodeKey(k: string): string {
        return decodeURIComponent(k);
    }

    decodeValue(v: string) {
        return decodeURIComponent(v);
    }
}
